<template>
  <v-dialog v-model="active" width="500" persistent>
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" v-if="showActivator" :color="buttonColor" text>{{ buttonText }}</v-btn>
    </template>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-html="cardText"></v-card-text>
      <v-card-text>
        <div id="paypal-button"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { dollarFormat } from '../../helpers/formatters'
import { loadScript } from '@paypal/paypal-js'
export default {
  props: {
    showActivator: {
      type: Boolean,
      default: true
    },
    buttonText: {
      type: String,
      default: 'Pay with PayPal'
    },
    buttonColor: {
      type: String,
      default: ''
    },
    total: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: 'Pay with PayPal'
    },
    text: {
      type: String,
      default: 'Click a button below to pay your total of {{TOTAL}} with PayPal.'
    },
    department: {
      type: String,
      required: true
    },
    foapal: Object
  },
  setup (props, { root, emit }) {
    const active = ref(false)
    function activate () {
      active.value = true
    }
    function cancel () {
      active.value = false
      emit('cancel')
    }

    const cardText = computed(() => {
      return props.text.replace('{{TOTAL}}', dollarFormat(props.total))
    })

    function createOrder (data, actions) {
      let itemTotal = 0
      for (const { quantity, unit_amount: { value } } of props.items) {
        if (quantity && value) itemTotal += quantity * value
      }
      let unit = {
        amount: {
          currency_code: 'USD',
          value: props.total
        }
      }
      if (props.items.length > 0) {
        unit.items = []
        props.items.forEach((item) => {
          unit.items.push({ ...item, unit_amount: { ...item.unit_amount, currency_code: 'USD' } })
        })
      }
      if (itemTotal > 0) unit.amount.breakdown = { item_total: { currency_code: 'USD', value: itemTotal } }
      console.log({ purchase_units: [ unit ] })
      return actions.order.create({
        purchase_units: [
          unit
        ]
      })
    }
    function onApprove (data, actions) {
      return actions.order.capture().then(async (orderData) => {
        // Store a copy of the transaction in the PayPal Payments service
        const { _id: logId } = await root.$feathers.service('system/paypal-log').create({
          paypalId: orderData.id,
          paymentDate: new Date(),
          payerName: orderData.payer.name.given_name + ' ' + orderData.payer.name.surname,
          payerEmail: orderData.payer.email_address,
          amount: props.total,
          department: props.department,
          foapal: props.foapal,
          items: props.items,
          orderData
        })
        emit('success', { logId, paypalId: orderData.id })
        active.value = false
      })
    }
    function onError (err) {
      // console.error(err)
      alert(err)
      window.location.href = '/your-error-page-here'
    }
    const style = {
      shape: 'pill',
      color: 'gold',
      // layout: 'horizontal',
      label: 'paypal',
      tagline: false
    }

    const paypal = ref('')

    onMounted(async () => {
      try {
        paypal.value = await loadScript({ 'client-id': process.env.VUE_APP_PAYPAL_CLIENT_ID, 'disable-funding': 'paylater' })
      } catch (error) {
        console.error('failed to load the PayPal JS SDK script', error)
      }
    })

    watch(active, () => {
      if (active.value && document.getElementById('paypal-button').children.length === 0) {
        let itemTotal = 0
        for (const { quantity, unit_amount: { value } } of props.items) {
          if (quantity && value) itemTotal += quantity * value
        }
        let unit = {
          amount: {
            currency_code: 'USD',
            value: props.total
          }
        }
        if (props.items.length > 0) {
          unit.items = props.items
        }
        if (itemTotal > 0) unit.breakdown = { item_total: { currency_code: 'USD', value: itemTotal } }
        console.log({ purchase_units: [ unit ] })
        root.$nextTick(async () => {
          if (paypal.value) {
            try {
              await paypal.value.Buttons({
                style,
                createOrder,
                onApprove,
                onError
              }).render('#paypal-button')
            } catch (error) {
              console.error('failed to render the PayPal Buttons', error)
            }
          }
        })
      }
    })

    return {
      active,
      activate,
      cancel,
      cardText,
      createOrder,
      onApprove,
      onError,
      style,
      paypal
    }
  }
}
</script>
